import "liquid-ajax-cart";
import $ from "cash-dom";
import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineGlobals from "../js/alpine/index.js";
import helpers, { hasBodyClass } from "../js/helpers.js";
import InfiniteMarquee from "vanilla-infinite-marquee";
import throttle from "raf-throttle";
import Swiper from "swiper";
import p5 from "p5";
import {
  Navigation,
  Pagination,
  A11y,
  Mousewheel,
  FreeMode,
} from "swiper/modules";
// Dynamic imports
hasBodyClass("product-template") && import("../js/prodify.js");

const ns = "slayed";

window.slayedNamespace = ns;
window[ns] = window[ns] || {};
window[ns].helpers = helpers;

for (const [key, value] of Object.entries(helpers)) {
  window[ns].helpers[key] = value;
}

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();

// Hide the Shopify preview bar when in development
// if (process.env.NODE_ENV === 'development') {
//   //
//   window.addEventListener('DOMContentLoaded', () => {
//     var css = '#preview-bar-iframe { display: none !important; }',
//       head = document.head || document.getElementsByTagName('head')[0],
//       style = document.createElement('style')

//     head.appendChild(style)

//     style.appendChild(document.createTextNode(css))
//   })
// }

const initSite = () => {
  const windowResizeEvents = () => {
    const root = document.documentElement;

    $("body").append(
      '<div class="resizing-helper" style="position:fixed;top:0;left:0;width:100%;height:100%;z-index:100;visibility:hidden;pointer-events:none;"><div class="container container-inset resizing-helper"><div class="content"></div></div></div>'
    );
    let leftOffset = $(".resizing-helper > .content").offset().left;
    $(".resizing-helper").remove();
    root.style.setProperty("--site-left-edge", `${leftOffset}px`);

    $("[data-expander-parent].open").each(function (idx, elem) {
      const content = $(elem).find("[data-expander-content]").first();
      $(content).css({
        maxHeight: getPotentialMaxHeight(content),
      });
    });
    let headerHeight = 0;
    if ($("header").length) {
      headerHeight = $("header").outerHeight();
    }
    root.style.setProperty("--header-height", `${headerHeight}px`);
  };

  const throttledResize = throttle(windowResizeEvents);
  window.addEventListener("resize", throttledResize);
  window.addEventListener("load", windowResizeEvents);

  const initCarousels = () => {
    $(".carousel").each(function (idx, elem) {
      const parent = $(elem),
        swiperElem = $(elem).find(".carousel-swiper")[0],
        paginationElem = $(elem).find(".swiper-pagination")[0],
        nextButtonElem = $(elem).find(".swiper-button-next")[0],
        prevButtonElem = $(elem).find(".swiper-button-prev")[0];
      const carouselSwiper = new Swiper(swiperElem, {
        slidesPerView: 1,
        spaceBetween: 0,
        modules: [Navigation, Pagination, A11y],
        rewind: false,
        loop: true,
        autoHeight: false,
        pagination: {
          el: paginationElem,
        },

        // Navigation arrows
        navigation: {
          nextEl: nextButtonElem,
          prevEl: prevButtonElem,
        },
      });
    });
  };

  initCarousels();

  const initMarquee = () => {
    new InfiniteMarquee({
      element: ".marquee-text",
      speed: 55000,
      smoothEdges: false,
      direction: "left",
      gap: "15px",
      duplicateCount: 2,
      mobileSettings: {
        direction: "top",
        speed: 55000,
      },
    });
  };

  initMarquee();

  function klaviyoSubscribe() {
    let emailInput = document.getElementById("email").value;
    const options = {
      method: "POST",
      headers: { revision: "2024-02-15", "content-type": "application/json" },
      body: JSON.stringify({
        data: {
          type: "subscription",
          attributes: {
            custom_source: "landing",
            profile: {
              data: { type: "profile", attributes: { email: emailInput } },
            },
          },
          relationships: { list: { data: { type: "list", id: "WCcWJc" } } },
        },
      }),
    };

    fetch(
      "https://a.klaviyo.com/client/subscriptions/?company_id=YtmAtx",
      options
    ).then(
      $(".klaviyo-form").html(
        "<p style='text-align:center;width:100%;'>THANK YOU FOR SUBSCRIBING.</p>"
      )
    );
  }
  const initKlaviyo = () => {
    $(".klaviyo-form").on("submit", (e) => {
      e.preventDefault();
      klaviyoSubscribe();
    });
  };
  initKlaviyo();

  const initFooter = () => {
    let footerSketch = function (p) {
      let radius = 500;
      let bgColor;
      let lastFrameCount = 0;
      let colorCycleCounter = 0;
      let oldWindowWidth = 0;
      let numOfRepeats = 12;
      let tintedLogos = [];
      let logoScale = 0.5;
      let easing = 0.1;
      let lastMouseVector;
      let logo = false;

      p.preload = function () {
        logo = p.loadImage($("#anim-container").attr("data-image"));
      };

      p.windowResized = function () {
        if (oldWindowWidth != p.windowWidth) {
          oldWindowWidth = p.windowWidth;
          p.resizeCanvas(p.windowWidth, p.windowHeight);
          let desiredLogoSize = p.max(p.windowWidth / 4, 300);
          logoScale = desiredLogoSize / logo.width;
        }
      };

      p.setup = function () {
        p.frameRate(60);
        p.createCanvas(p.windowWidth, p.windowHeight);
        p.windowResized();
        lastMouseVector = p.createVector(0, 0);
        // createCanvas(windowWidth, windowHeight, WEBGL);
        for (var i = 0; i < numOfRepeats; i++) {
          const pg = p.createGraphics(logo.width, logo.height);

          let alpha = 255 - i * 10;
          pg.imageMode(p.CORNER);
          let darkness = p.color(alpha, alpha, alpha);
          pg.tint(darkness, darkness);
          pg.image(logo, 0, 0);

          var tLogo = pg.get();
          tintedLogos.push(tLogo);
        }
      };

      p.draw = function () {
        p.background(0); // Clear the background every frame
        p.noStroke();
        let center = p.createVector(
          p.width / 2 - (logo.width * logoScale) / 2,
          p.height / 2 - (logo.height * logoScale) / 2
        );
        let thisMouseVec = p.createVector(
          p.mouseX - p.width / 2,
          p.mouseY - p.height / 2
        );

        //go backwards
        for (let i = numOfRepeats - 1; i >= 0; i--) {
          if (p.frameCount - lastFrameCount > 4) {
            lastFrameCount = p.frameCount;
            colorCycleCounter++;
          }
          let offset = (colorCycleCounter + i) % numOfRepeats;
          let mouseVec = p.createVector(
            p.mouseX - p.width / 2,
            p.mouseY - p.height / 2
          );
          lastMouseVector.lerp(mouseVec, 0.5);

          mouseVec.mult(i / numOfRepeats);
          p.image(
            tintedLogos[offset],
            center.x + mouseVec.x,
            center.y + mouseVec.y,
            logo.width * logoScale,
            logo.height * logoScale
          );
        }
        lastMouseVector = thisMouseVec;
        // lastMouseVector = thisMouseVector.copy();

        // center logo on top

        p.image(
          logo,
          center.x,
          center.y,
          logo.width * logoScale,
          logo.height * logoScale
        );
      };
    };

    new p5(footerSketch, "anim-container");
  };

  let ua = window.navigator.userAgent;
  let iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
  let webkit = ua.match(/WebKit/i);
  let iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
  if(!iOSSafari){
    initFooter();
  } else {
    let img = new Image();
    img.src = $("#anim-container").attr("data-image");
    img.classList.add("pt-[180px]");
    $("#anim-container").append(img);
  }

  // initFooter();
};
initSite();
